<template>
  <div>
    <div class="container">
      <div class="banner">
        <van-image
          class="banner_img"
          mode="widthFix"
          :src="require('@/assets/images/banner.jpg')"
        />
        <div class="banner_text">
          <div class="banner_text_title">{{ $t("月月发券，四季常有") }}</div>
          <div class="banner_text_subtitle">
            {{ $t("每月1号开始可领取月度优惠券") }}
          </div>
        </div>
      </div>

      <van-tabs color="#40c057" v-model="active" sticky>
        <van-tab :title="$t('可用券')">
          <div>
            <van-pull-refresh
              v-model="unusedParams.refreshing"
              @refresh="onRefresh"
              :pulling-text="$t('下拉即可刷新...')"
              :loosing-text="$t('释放即可刷新...')"
              :loading-text="$t('加载中...')"
            >
              <van-list
                v-model="unusedParams.loading"
                :finished="unusedParams.finished"
                :style="{ 'min-height': screenHeight + 'px' }"
                :finished-text="$t('没有更多了')"
                @load="onLoad"
              >
                <div class="coupon" v-for="item in unusedList" :key="item.id">
                  <div class="coupon_middle">
                    <div class="coupon_title">
                      <div class="coupon_title--primary">
                        {{ item.coupon.title }}
                      </div>
                      <div class="coupon_title--sub">
                        {{ $t("有效期至") }} {{ formatDate(item.expiredAt) }}
                      </div>
                    </div>
                    <div v-if="item.coupon.type == 1" class="coupon_amount">
                      <span class="coupon_amount_currency">฿</span>
                      <span>{{ item.coupon.value }}</span>
                    </div>
                    <div
                      v-else-if="item.coupon.type == 2"
                      class="coupon_amount"
                    >
                      <span>{{ item.coupon.value * 10 }}</span>
                      <span class="coupon_discount">{{ $t("折") }}</span>
                    </div>
                  </div>

                  <div class="coupon_bottom">
                    <div class="coupon_desc">
                      {{
                        item.coupon.minimumAmount == 0
                          ? $t("无门槛")
                          : $t("满可用", { amount: item.coupon.minimumAmount })
                      }}
                    </div>
                    <div
                      v-if="item.expired && item.status != 2"
                      class="coupon_status"
                    >
                      {{ $t("已过期") }}
                    </div>
                    <!-- <div wx:if="{{!item.expired && item.status == 1}}" class="coupon_status">付款时可使用</div> -->
                    <div v-if="item.status == 2" class="coupon_status">
                      {{ $t("使用日期") }}：{{ formatDate(item.usedAt) }}
                    </div>
                  </div>
                </div>
              </van-list>
            </van-pull-refresh>
          </div>
        </van-tab>
        <van-tab :title="$t('已用券')"
          ><div>
            <van-pull-refresh
              v-model="usedParams.refreshing"
              @refresh="onRefresh"
              :pulling-text="$t('下拉即可刷新...')"
              :loosing-text="$t('释放即可刷新...')"
              :loading-text="$t('加载中...')"
            >
              <van-list
                v-model="usedParams.loading"
                :finished="usedParams.finished"
                :style="{ 'min-height': screenHeight + 'px' }"
                :finished-text="$t('没有更多了')"
                @load="onLoad"
              >
                <div class="coupon" v-for="item in usedList" :key="item.id">
                  <div class="coupon_middle">
                    <div class="coupon_title">
                      <div class="coupon_title--primary">
                        {{ item.coupon.title }}
                      </div>
                      <div class="coupon_title--sub">
                        {{ $t("有效期至") }} {{ formatDate(item.expiredAt) }}
                      </div>
                    </div>
                    <div v-if="item.coupon.type == 1" class="coupon_amount">
                      <span class="coupon_amount_currency">฿</span>
                      <span>{{ item.coupon.value }}</span>
                    </div>
                    <div
                      v-else-if="item.coupon.type == 2"
                      class="coupon_amount"
                    >
                      <span>{{ item.coupon.value * 10 }}</span>
                      <span class="coupon_discount">{{ $t("折") }}</span>
                    </div>
                  </div>

                  <div class="coupon_bottom">
                    <div class="coupon_desc">
                      {{
                        item.coupon.minimumAmount == 0
                          ? $t("无门槛")
                          : $t("满可用", { amount: item.coupon.minimumAmount })
                      }}
                    </div>
                    <div
                      v-if="item.expired && item.status != 2"
                      class="coupon_status"
                    >
                      {{ $t("已过期") }}
                    </div>
                    <!-- <div wx:if="{{!item.expired && item.status == 1}}" class="coupon_status">付款时可使用</div> -->
                    <div v-if="item.status == 2" class="coupon_status">
                      {{ $t("使用日期") }}：{{ formatDate(item.usedAt) }}
                    </div>
                  </div>
                </div>
              </van-list>
            </van-pull-refresh>
          </div></van-tab
        >
      </van-tabs>
    </div>
  </div>
</template>

<script>
import { Image as VanImage, Tab, Tabs, List, PullRefresh, Toast } from "vant";
import { availbleList } from "@/service/api";
import { formatDate } from "@/utils/utils";

export default {
  components: {
    [VanImage.name]: VanImage,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    [List.name]: List,
    [PullRefresh.name]: PullRefresh,
  },
  data() {
    return {
      unusedList: [],
      usedList: [],
      active: 0,
      screenHeight: 0,
      unusedParams: {
        page: 0,
        loading: false,
        finished: false,
        refreshing: false,
      },
      usedParams: {
        page: 0,
        loading: false,
        finished: false,
        refreshing: false,
      },
    };
  },
  mounted() {
    this.screenHeight = document.documentElement.clientHeight - 98;
  },
  methods: {
    onRefresh() {
      switch (this.active) {
        case 0:
          this.unusedParams.loading = true;
          this.unusedParams.page = 1;
          this.availbleList();
          break;
        case 1:
          this.usedParams.loading = true;
          this.usedParams.page = 1;
          this.availbleList();
          break;
      }
    },
    onLoad() {
      switch (this.active) {
        case 0:
          this.unusedParams.loading = true;
          this.unusedParams.page = this.unusedParams.page + 1;
          this.availbleList();
          break;
        case 1:
          this.usedParams.loading = true;
          this.usedParams.page = this.usedParams.page + 1;
          this.availbleList();
          break;
      }
    },
    async availbleList() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: "加载中...",
      });

      let params = {
        pageSize: 10,
      };
      switch (this.active) {
        case 0:
          params = {
            ...params,
            currentPage: this.unusedParams.page,
            status: 1,
            availble: true,
            sorter: "expiredAt_asc",
          };
          break;
        case 1:
          params = {
            ...params,
            currentPage: this.usedParams.page,
            expiredOrUsed: true,
            sorter: "usedAt_desc",
          };
          break;
      }

      const res = await availbleList(params);

      if (res.success) {
        const { data, total } = res;
        switch (this.active) {
          case 0:
            this.unusedParams.finished = true;
            this.unusedParams.refreshing = false;
            this.unusedParams.loading = false;

            if (this.unusedParams.page === 1) this.unusedList = [];
            this.unusedList = this.unusedList.concat(data);

            this.unusedParams.finished = this.unusedList.length >= total;
            break;
          case 1:
            this.usedParams.finished = true;
            this.usedParams.refreshing = false;
            this.usedParams.loading = false;

            if (this.usedParams.page === 1) this.usedList = [];
            this.usedList = this.usedList.concat(data);

            this.usedParams.finished = this.usedList.length >= total;
            break;
        }
      }
      Toast.clear();
    },
    formatDate(value) {
      return formatDate(value);
    },
  },
};
</script>
<style lang="less" scoped>
.container {
  padding: 10px 5px;
}
.banner {
  position: relative;
  margin-bottom: 5px;
}
.banner_img {
  width: 100%;
}
.banner_text {
  position: absolute;
  /* width: 200px; */
  text-align: left;
  left: 30%;
  top: 50%;
  /* margin-left: -50px; */
  /* transform: translateX(-50%); */
  transform: translateY(-50%);
}
.banner_text_title {
  font-size: 18px;
  color: #7e4504;
}
.banner_text_subtitle {
  font-size: 14px;
  color: rgb(129, 88, 25);
}

.coupon {
  margin-top: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.coupon_middle {
  padding: 15px;
  display: flex;
  align-items: center;
}
.coupon_title {
  flex: 2;
}
.coupon_title--primary {
  font-size: 16px;
}
.coupon_title--sub {
  margin-top: 8px;
  font-size: 14px;
  color: #abacad;
}
.coupon_amount {
  flex: 1;
  text-align: right;
  font-size: 24px;
  color: #f2826a;
}
.coupon_amount_currency {
  font-size: 10px;
  margin-right: 5px;
}

.coupon_bottom {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  margin: 0px 15px;
  border-top: 1px dashed #abacad;
}
.coupon_desc {
  flex: 1;
  font-size: 14px;
  color: #abacad;
}
.coupon_status {
  flex: 1;
  text-align: right;
  font-size: 14px;
  color: #f2826a;
}
</style>